// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group functions
////

$-zf-font-stack: (
  'georgia': (Georgia, "URW Bookman L", serif),
  'helvetica': (Helvetica, Arial, "Nimbus Sans L", sans-serif),
  'lucida-grande': ("Lucida Grande", "Lucida Sans Unicode", "Bitstream Vera Sans", sans-serif),
  'monospace': ("Courier New", Courier, "Nimbus Sans L", monospace),
  'system': (-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Helvetica Neue", Helvetica, Arial, sans-serif),
  'verdana': (Verdana, Geneva, "DejaVu Sans", sans-serif),
);

/// Return a font stack list from a map. Equivalent to `map-safe-get($name, $-zf-font-stack)`.
///
/// @param {String} $stack - Name of the font stack.
/// @param {Map} $map [$-zf-font-stack] - Map of font stacks to retrieve a list from.
///
/// @returns {List} Found font stack.
@function font-stack($stack, $map: $-zf-font-stack) {
  @return map-safe-get($map, $stack);
}
