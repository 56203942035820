.pa-grid {
  .pa {
    min-height: 130px;
    box-shadow: $global-box-shadow;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 25px 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $primary-color;
    h5 {
      padding-top: 5px;
      color: $body-font-color;
      font-size: 1.5em;
    }

    i {
      color: #f3f3f3;
      background: #695e4a;
      padding: 5px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      font-size: 20px;
      line-height: 15px;
    }
  }
}
