$global-box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);

.grid-x {
  max-width: $max-width;
  margin: auto;
}

.button.inverted {
  background: none;
  border: 2px solid $primary-color;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  padding: 15px 40px;
}

button,
.button {
  text-transform: uppercase;
  font-weight: bold;
}

.about-btn-contain a {
  float: right;
}

.with-icon {
  color: $body-font-color;
  display: block;
  padding: 2px 0;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  i {
    color: $primary-color;
    padding-right: 8px;
    width: 30px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.segment {
  padding: 50px 0;
  min-height: 60vh;
  background: #fff;

  &.concrete {
    background-color: #e8e8e8;
    background-image: url("/images/concrete.png");
  }

  &#map {
    height: 60vh;
    padding: 0;
  }
}

.wrapper {
  min-height: 60vh;
  p,
  li {
    font-size: 18px;
    line-height: 1.8em;
  }
}

//Semantic Style mixins / Classes

@mixin down-x($x) {
  padding-top: $x;
}

@mixin spread-x($x) {
  padding-left: $x;
  padding-right: $x;
}

@mixin bot-x($x) {
  padding-bottom: $x;
}

@mixin pad-xy($x, $y) {
  padding: $y $x;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pad-half {
  padding: 0.5em;
}

.pad-1 {
  padding: 1em;
}

.pad-2 {
  padding: 2em;
}

.pad-3 {
  padding: 3em;
}

/* Bottom*/

.bot-half {
  padding-bottom: 0.5em;
}

.bot-1 {
  padding-bottom: 1em;
}

.bot-2 {
  padding-bottom: 2em;
}

.bot-3 {
  padding-bottom: 3em;
}

/* Spread*/

.spread-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.spread-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.spread-3 {
  padding-left: 3em;
  padding-right: 3em;
}

/* Down*/

.down-1 {
  padding-top: 1em;
}

.down-2 {
  padding-top: 2em;
}

.down-3 {
  padding-top: 3em;
}

.down-half {
  padding-top: 0.5em;
}

@media screen and (max-width: 39.9375em) {
  .about-btn-contain {
    a {
      float: none;
    }
    text-align: center;
  }

  .small-only-text-center {
    text-align: center;
  }
}
