$foundation-palette: (
  primary: #b0111a,
  secondary: #17194b,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;

$bg-color: #f7f6ee;

$max-width: 1500px;

$footer-height: 90px;
$nav-height: 110px;

$header-font-family: "Neuton", Helvetica, Roboto, Arial, sans-serif;
$header-color: #b0111a;

$body-font-family: "IBM Plex Sans", Helvetica, Roboto, Arial, sans-serif;
