$nav-height: 85px;

.top-nav-container {
  position: fixed;
  width: 100%;
  z-index: 9999;

  .top-nav {
    width: 98%;
    max-width: $max-width;
    background: #fff;
    margin: auto;
    display: block;
    position: relative;
    height: $nav-height;
    z-index: 9999;
    box-shadow: $global-box-shadow;
    padding: 0 25px;

    .logo {
      height: 40px;
      margin-top: 22.5px;
    }

    .mobile_logo {
      display: none;
    }

    .show-mobile-nav {
      color: $primary-color;
      font-size: 30px;
      top: 25px;
      right: 20px;
      position: absolute;
      display: none;
      transition: 0.5s all;
      -webkit-transition: 0.5s all;

      &:hover {
        color: $secondary-color;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      height: $nav-height;
      float: right;

      li {
        display: inline-block;
        height: $nav-height;
        position: relative;

        .vsep {
          height: 50px;
          line-height: 50px;
          width: 2px;
          background: #ebebeb;
        }

        &.direct-contact a {
          text-transform: none;
          letter-spacing: 0;
        }

        a {
          line-height: 80px;
          height: $nav-height;
          display: inline-block;
          padding: 0 7px;
          border-bottom: 4px solid transparent;
          margin-bottom: 4px;
          font-weight: bold;
          color: $body-font-color;
          font-size: 0.9em;
          transition: 0.4s all;
          -webkit-transition: 0.4s all;

          i {
            color: $primary-color;
            padding-right: 5px;
            font-size: 1.1em;
          }

          &.active,
          &:hover {
            border-bottom: 4px solid $primary-color;
            color: $primary-color;
          }

          &.unset-caps {
            text-transform: none;
          }
        }
      }
    }
  }
}

.foot-nav {
  position: absolute;
  bottom: 0;
  width: 90%;
  margin: auto;
  max-width: 1500px;
  left: 0;
  right: 0;
  background: #fff;
  height: 80px;

  .copyright {
    line-height: 80px;
    padding: 0 15px;
    margin: 0;
  }

  .backtotop {
    font-size: 50px;
    position: absolute;
    right: 15px;
    top: 15px;
    color: $medium-gray;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;

    &:hover {
      color: $primary-color;
    }
  }
}
@media screen and (max-width: 74.9375em) {
  .top-nav-container {
    .top-nav {
      width: 100%;
      .logo {
        display: none;
      }

      .mobile_logo {
        display: block;
        position: absolute;
        height: 30px;
        left: 15px;
        top: 25px;
      }

      .show-mobile-nav {
        display: block;
        top: 25px;
        margin: 0;
      }

      ul {
        background: #e8e8e8;
        position: absolute;
        top: $nav-height;
        margin: 0;
        left: 0;
        display: none;
        width: 100%;

        &.active {
          display: block;
        }

        li {
          width: 100%;
          background: #e8e8e8;
          height: 60px;

          &.vsep-contain {
            display: none;
          }

          a,
          a.active {
            width: 100%;
            padding: 0 25px;
            height: 60px;
            line-height: 60px;
            border-bottom: 2px solid $medium-gray;

            &:hover {
              background: $primary-color;
              color: #fff;
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
