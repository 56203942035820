.midcon {
  background-image: url("/assets/img/hero2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: $secondary-color;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.hero {
  width: 100%;
  height: 45vh;
  min-height: 300px;

  img {
    display: block;
    margin: auto;
    position: absolute;
    right: 15vw;
    padding: 2em 3em;
    top: 15vh;
    width: 40vw;
    animation: fadein 1.5s;
    -moz-animation: fadein 1.5s; /* Firefox */
    -webkit-animation: fadein 1.5s; /* Safari and Chrome */
    -o-animation: fadein 1.5s; /* Opera */
    background: transparentize(#fff, 0.15);
  }
}

@media screen and (max-width: 50em) {
  .hero img {
    width: 80vw;
  }

  .hero {
    height: 35vh;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
    -moz-transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
