#attorneys-grid {
  padding-top: 20px;
  padding-bottom: 40px;
  .attorney {
    padding: 0 0 20px 0;
    margin: 0 0 15px 0;
    display: block;
    transition: 0.4s all ease-out;
    -webkit-transition: 0.4s all ease-out;
    background: #fff;
    box-shadow: $global-box-shadow;
    border-bottom: 1px solid $primary-color;
    position: relative;
    transform: translateY(0);

    picture {
      position: relative;
      display: flex;
      width: 100%;
      height: 250px;
      overflow: hidden;
      img {
        object-position: top center;
        min-width: 100%;
        object-fit: cover;
        transition: 0.4s all ease-out;
        -webkit-transition: 0.4s all ease-out;
      }
    }

    h3 {
      font-size: 1.5em;
      padding-top: 5px;
      margin: 0;
    }

    p {
      margin: 0;
      padding: 0;
      color: $body-font-color;
      font-weight: bold;
      font-size: 0.9em;
    }

    &:hover {
      transform: translateY(-8px);

      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }
}

.contact-links {
  padding-top: 0.5em;
  a {
    display: block;
    padding: 0.5em;
    width: 200px;
    margin: 10px auto;
    font-weight: bold;
    color: $secondary-color;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    border: 2px solid $secondary-color;

    i {
      padding-right: 5px;
    }

    &:hover {
      border: 2px solid $primary-color;
    }
  }
}
