footer {
  width: 100%;
  height: 25vh;
  min-height: 300px;
  position: relative;

  .contact-btn {
    margin-top: 75px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px 75px;
    font-size: 1.1em;

    i {
      padding-right: 5px;
    }
  }
}

@media screen and (max-width: 39.9375em) {

  footer p {
    font-size: .8em;
  }
}
